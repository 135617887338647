/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFlags } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { AuthScreenProps, LDFlags } from "../../../@types";
import { createAppleSession, createSession } from "../../api";
import Error from "../../components/Error";
import I18n from "../../components/I18n";
import Layout from "../../components/Layout/Layout";
import AppleButton from "../../components/OAuthButtons/AppleButton";
import FbButton from "../../components/OAuthButtons/FbButton";
import PhoneNumberField from "../../components/PhoneNumberField";
import SquareButton from "../../components/SquareButton/SquareButton";
import { RECAPTCHA_ACTION, SEGMENT_EVENTS } from "../../constants";
import { useTracking } from "../../contexts/useTracking";
import { useUserLocation } from "../../contexts/useUserLocation";
import {
  displayError,
  getFormattedPhoneNumber,
  isLoginFlow,
  validators,
} from "../../helpers";
import { useReCaptcha } from "../../hooks/useReCaptcha";

interface Props extends RouteComponentProps {
  errorCode: string;
  transitionTo: (nextState: any) => void;
}
interface PhoneAuthFormData {
  phone_number: string;
}

const PhoneOrOAuthScreen = ({ errorCode, transitionTo }: AuthScreenProps) => {
  const flags = useFlags() as LDFlags;
  const { i18n, t } = useTranslation();
  const { initializeReCaptcha, getReCaptchaToken } = useReCaptcha(
    i18n.language,
  );
  const ldAuthMethods =
    flags?.passengerDriverDisplayLoginMethodsInHomescreen || {
      facebook: true,
      phone_password: true,
      apple: false,
    };
  const { country } = useUserLocation();
  const [error, setError] = useState(errorCode);
  const { trackEvent } = useTracking();
  const [isAppleLoading, setIsAppleLoading] = useState(false);
  let messageCodePrefix = "phoneOrFacebook";
  if (
    ldAuthMethods.apple &&
    ldAuthMethods.facebook &&
    ldAuthMethods.phone_password
  ) {
    messageCodePrefix = "phoneOrOAuth";
  }
  const subtitleMessageId = isLoginFlow()
    ? `${messageCodePrefix}.helper-login`
    : `${messageCodePrefix}.helper-signup`;

  const trackFbButton = () =>
    trackEvent(SEGMENT_EVENTS.CONNEXION_CONTINUE_WITH_FB_TAPPED);

  const submitWithReCaptcha = ({ phone_number, recaptcha_token }) =>
    createSession({ phone_number, recaptcha_token })
      .then(({ data }) => {
        transitionTo(data);
      })
      .catch(apiError => {
        displayError(setError(apiError?.message));
      });

  const onPhoneNumberSubmit = (formData: PhoneAuthFormData) => {
    const phone_number = getFormattedPhoneNumber(formData?.phone_number);
    if (validators.phoneNumber(formData.phone_number)) {
      trackEvent(SEGMENT_EVENTS.CONNEXION_CONTINUE_TAPPED, {
        phone_number,
      });
    }
    getReCaptchaToken({
      action: RECAPTCHA_ACTION,
      onReCaptchaTokenValidate: recaptcha_token =>
        submitWithReCaptcha({
          phone_number,
          recaptcha_token,
        }),
    });
  };

  useEffect(() => {
    if (!window?.grecaptcha) {
      initializeReCaptcha();
    }
  }, [initializeReCaptcha]);

  return (
    <Layout
      title={<I18n id={`${messageCodePrefix}.title`} />}
      subtitle={<I18n id={subtitleMessageId} />}
      withRestartLink={false}
    >
      {error && <Error error={error} />}

      {ldAuthMethods.phone_password && (
        <Form onSubmit={onPhoneNumberSubmit}>
          {({ handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <PhoneNumberField
                country={country}
                disabled={submitting || isAppleLoading}
              />

              <SquareButton
                type="submit"
                backgroundColor="rgb(77, 148, 255)"
                text={t("buttons.continue")}
                disabled={isAppleLoading || submitting}
                isLoading={submitting}
                IconCheck={false}
                onClick={undefined}
              />
            </form>
          )}
        </Form>
      )}

      {ldAuthMethods.facebook && (
        <FbButton onClick={trackFbButton} disabled={isAppleLoading}>
          <I18n id="buttons.continue-fb" />
        </FbButton>
      )}

      {ldAuthMethods.apple && (
        <AppleButton
          language={i18n.language}
          type="sign in"
          isLoading={isAppleLoading}
          onSigninSuccess={appleSigninData => {
            setIsAppleLoading(true);
            const { code } = appleSigninData.detail.authorization;
            createAppleSession({
              code,
            })
              .then(({ data }) => {
                transitionTo(data);
                setIsAppleLoading(false);
              })
              .catch(() => {
                displayError(setError);
                setIsAppleLoading(false);
              });
          }}
          onSinginError={() => {
            setError("apple_login_failed");
          }}
        />
      )}
    </Layout>
  );
};

PhoneOrOAuthScreen.propTypes = {
  errorCode: PropTypes.string,
  transitionTo: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

PhoneOrOAuthScreen.defaultProps = {
  errorCode: undefined,
};

export default PhoneOrOAuthScreen;
